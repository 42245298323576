import './App.scss'
import {Routes, Route} from 'react-router-dom';

import Projects from './components/Projects'

function App() {
  return (
    
    <div className='App'>
      
   <Routes>
    <Route path="/" index element={<Projects />}/>


      
   </Routes>
   </div>
    
    
  );
}



export default App;
