import './index.scss'

const Projects = () => {

    return(
        <>
        <div className="container project-page">
            <div className="text-zone">
                <h1>Projects</h1>
            </div>


        </div>
        
        
        </>
    )
}

export default Projects;